<template>
  <div id="order" class="grid">
    <div class="pg-header">
      <div class="pg-back-button">
        <button class="btn-primary" @click="back">Tillbaka</button>
      </div>

      <h2 v-if="orderId < 1">Ny order</h2>
      <h2 v-if="orderId > 0">Editera order</h2>
    </div>
    <div class="tab-container">
      <tabs :id="orderId" :origin-view="originView"></tabs>
    </div>
  </div>
</template>

<script>
import PGTabsOrder from './tabs/PGTabs-Order';

export default {
  name: 'Order',
  components: {
    tabs: PGTabsOrder
  },
  props: {
    orderId: {
      type: Number,
      default: 0
    },
    originView: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      search: '',
      showError: false,
      error: '',
      myOrderFiles: [],
      myOrder: {
        order_id: 0,
        order_number: '',
        prio_number: 0,
        customer_name: '',
        delivery_date: new Date(),
        changed_delivery_date: '',
        color: '',
        palette_amount: 1,
        order_status_id: 1,
        comments: '',
        delivery_id: '',
        order_link: '',
        email_address: '',
        email_sent_date: null
      }
    };
  },
  computed: {},
  created() {},
  async mounted() {},
  methods: {
    back: function () {
      this.$router.replace({
        name: this.originView,
        params: {
          addUpdateOK: false,
          hasAddUpdate: false,
          action: ''
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.pg-form,
.pg-header {
  grid-column: 5/9;
}
.tab-container {
  grid-column: 4/10;
  text-align: center;
}
@media (max-width: 1690px) {
  .pg-form,
  .pg-header {
    grid-column: 4/10;
  }
}
@media (max-width: 1270px) {
  .pg-form,
  .pg-header {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
</style>
