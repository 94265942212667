<template>
  <div id="order-info" class="grid">
    <div class="pg-header">
      <h2>Orderinformation</h2>
    </div>
    <div class="pg-order-info">
      <div v-if="myOrder.order_id > 0" class="order-info-container">
        <div class="pg-order-info-group">
          <label class="pg-label">Ordernummer</label>
          <div class="pg-text">{{ myOrder.order_number }}</div>
        </div>
        <hr />
        <div class="pg-order-info-group">
          <label class="pg-label">Orderstatus</label>
          <div class="pg-text">{{ myOrder.order_status_name }}</div>
        </div>
        <hr />
        <div class="pg-order-info-group">
          <label class="pg-label">Prel. leveransdatum</label>
          <div v-if="myOrder.changed_delivery_date.length > 0" class="pg-text">
            {{ myOrder.changed_delivery_date }}
          </div>
          <div v-else class="pg-text">{{ myOrder.delivery_date }}</div>
        </div>
        <hr />
      </div>

      <div v-if="loading && errorMessage === ''" class="pg-center">
        <Spinner></Spinner>
      </div>
      <div v-if="errorMessage !== ''" class="pg-center">
        <p class="errorMessageText">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
//import { format } from 'date-fns';
import Spinner from '@/components/utils/Spinner';
export default {
  name: 'OrderInfo',
  components: {
    Spinner
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      order_link: this.$route.params.id,
      myOrder: {
        order_id: 0,
        order_number: '',
        customer_name: '',
        delivery_date: new Date(),
        changed_delivery_date: null,
        color: '',
        palette_amount: 1,
        order_status_id: 1,
        comments: '',
        delivery_id: '',
        order_link: '',
        email_address: '',
        email_sent_date: null
      }
    };
  },
  created() {},
  async mounted() {
    if (this.order_link.length > 20) {
      this.loading = true;
      await this.fetchMyOrder();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchCustomerOrder: 'orders-utils/fetchCustomerOrder'
    }),
    async fetchMyOrder() {
      try {
        this.myOrder = [];
        let data = {
          order_link: this.order_link
        };
        this.myOrder = await this.fetchCustomerOrder(data);
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    }
  }
};
</script>

<style lang="scss">
.pg-order-info {
  grid-column: 5/9;
}
.pg-order-info-group {
  display: flex;
  justify-content: space-between;
}
.pg-label {
  font-size: 1rem;
}
.pg-text {
  margin: 5px 0;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
}
.order-info-container {
  margin-left: auto;
  margin-right: auto;

  background: rgba(255, 255, 255, 0.3);
  padding: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
@media (max-width: 1590px) {
  .pg-order-info {
    grid-column: 4/10;
  }
}
@media (max-width: 1270px) {
  .pg-order-info {
    grid-column: 3/11;
  }
}
@media (max-width: 800px) {
  .pg-order-info {
    grid-column: 2/12;
  }
}
@media (max-width: 600px) {
  .pg-order-info {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
</style>
