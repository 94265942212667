<template>
  <div id="order-files" class="grid">
    <div class="pg-form">
      <hr />
      <div class="pg-header">
        <h4>Lägg till PDFer</h4>
        <p>(Max 4st)</p>
      </div>
      <div class="pg-form-group">
        <dropzone :id="orderId" :ordernr="myOrderNumber" :files="myOrderFiles"></dropzone>
      </div>

      <hr />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PGDropzone from '@/components/dropzone/PGDropzone.vue';

export default {
  name: 'PGTabOrderFiles',
  components: {
    dropzone: PGDropzone
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    originView: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      errorMessage: '',
      showError: false,
      orderId: this.id,
      myOrderNumber: '123',
      myOrderFiles: [],
      myOrder: {
        order_id: 0,
        order_number: '',
        prio_number: 0,
        customer_name: '',
        delivery_date: new Date(),
        changed_delivery_date: '',
        color: '',
        palette_amount: 1,
        order_status_id: 1,
        comments: '',
        delivery_id: '',
        order_link: '',
        email_address: '',
        email_sent_date: null
      }
    };
  },
  async mounted() {
    if (this.orderId > 0) {
      await this.fetchMyOrder();
      await this.fetchMyOrderFiles();
    }
  },
  methods: {
    ...mapActions({
      fetchOrderFiles: 'orders-utils/fetchOrderFiles',
      fetchOrder: 'orders/fetchOrder'
    }),
    async fetchMyOrderFiles() {
      try {
        this.myOrderFiles = [];
        let data = {
          order_id: this.orderId
        };
        this.myOrderFiles = await this.fetchOrderFiles(data);
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async fetchMyOrder() {
      try {
        this.myOrder = {};
        let data = {
          order_id: this.orderId
        };
        this.myOrder = await this.fetchOrder(data);
        this.myOrderNumber = this.myOrder.order_number;
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/_variables.scss';

.pg-form {
  grid-column: 5/9;
}

@media (max-width: 1690px) {
  .pg-form {
    grid-column: 4/10;
  }
}
@media (max-width: 1270px) {
  .pg-form {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
</style>
