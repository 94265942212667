<template>
  <div class="pg-tabs">
    <ul class="pg-tab-header">
      <li>
        <a href="" :class="{ active: currentTab == 1 }" @click.prevent="selectTab(1)">Orderinformation</a>
      </li>
      <li v-if="id > 0">
        <a href="" :class="{ active: currentTab == 2 }" @click.prevent="selectTab(2)">Dokument</a>
      </li>
    </ul>

    <div class="tab-body">
      <div v-if="currentTab == 1" class="pg-tab-content">
        <tabOrderBasics :id="id" :origin-view="originView"></tabOrderBasics>
      </div>

      <div v-if="currentTab == 2" class="pg-tab-content">
        <tabOrderFiles :id="id" :origin-view="originView"></tabOrderFiles>
      </div>
    </div>
  </div>
</template>

<script>
import PGTabOrderBasics from './PGTab-OrderBasics';
import PGTabOrderFiles from './PGTab-OrderFiles';

export default {
  name: 'PGTabsOrder',
  components: {
    tabOrderBasics: PGTabOrderBasics,
    tabOrderFiles: PGTabOrderFiles
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    originView: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentTab: 1
    };
  },
  methods: {
    selectTab(selectedTab) {
      this.currentTab = selectedTab;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/_variables.scss';
.pg-tabs {
  //width: 1100px;
  //height: auto;
  // margin: 0 auto;

  .pg-tab-header {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px 0;
    background-color: #fff;
    align-items: center;
    justify-content: center;

    li {
      display: inline;

      a {
        color: $secondary;
        text-decoration: none;
        font-size: 1rem;

        font-weight: $font-weight-bold;
        //text-transform: uppercase;
        padding: 5px 5px;
        margin: 0 10px;

        //   outline: none;
      }
      a.active {
        border-bottom: 2px solid $primary;
        color: $dark;
      }
    }
  }

  .pg-tab-item {
    background: #fff;
  }

  .pg-tab-item-active {
    background: #ffddaa;
  }
  .pg-tab-content {
    background: #fff;
  }
}
</style>
