<template>
  <form enctype="multipart/form-data" @submit.prevent="sendFile1" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <div v-if="message" class="message">{{ message }}</div>
    <div class="dropzone">
      <input ref="fileUploader" type="file" class="input-uploader" multiple accept=".pdf" @change.prevent="sendFile" />
      <img v-if="!uploading" src="./img/cloud-upload-alt-solid.svg" class="svg-icon-upload" />
      <div v-if="uploading" class="progress-bar">
        <progress-bar :percentage="progress"></progress-bar>
      </div>
    </div>
    <br />
    <div class="file-area">
      <div v-for="file in uploadedFiles" :key="file" class="single-file">
        <img class="file-image" src="./img/Adobe-PDF-File-Icon-01.png" />
        <div class="file-overlay">
          <div class="file-button">
            <a href="#" @click.prevent="clickPDF(file)"><img src="./img/file-pdf-solid.svg" class="svg-icon-button" title="Visa PDF" /></a>
          </div>
          <div class="file-button">
            <a href="#" @click.prevent="downloadPDF(file)"><img src="./img/file-download-solid.svg" class="svg-icon-button" title="Ladda ner PDF" /></a>
          </div>
        </div>
        <div class="file-text">{{ file.substring(file.lastIndexOf('/') + 1) }}</div>
        <a class="remove-file" href="#" @click.prevent="deleteFile(file)">&#215;</a>
      </div>
    </div>
    <div v-if="loading && errorMessage === ''" class="pg-center">
      <Spinner></Spinner>
    </div>
    <div v-if="errorMessage !== ''" class="pg-center">
      <p class="errorMessageText">{{ errorMessage }}</p>
    </div>
    <pdf-modal v-show="isModalVisible" :order="myOrder" :force="forceUpdateModalCounter" @close="closeModal"></pdf-modal>
  </form>
</template>

<script>
// https://youtu.be/7j9t_7FcXLw
import { mapActions } from 'vuex';
import axiosAPI from '@/services/axiosAPI';
import PGProgressBar from './PGProgressBar';
import Spinner from '@/components/utils/Spinner';
import PGPDFModal from '@/components/PG-PDFModal.vue';

export default {
  name: 'PGDropzone',
  components: {
    'progress-bar': PGProgressBar,
    Spinner,
    'pdf-modal': PGPDFModal
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    id: {
      type: Number,
      default: 0,
      required: true
    },
    ordernr: {
      type: String,
      default: '0',
      required: true
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      showModal: false,
      isModalVisible: false,
      forceUpdateModalCounter: 0,
      file: '',
      message: '',
      error: false,
      uploading: false,
      uploadedFiles: [],
      progress: 0,
      orderId: this.id,
      orderNumber: '',
      myOrder: {}
    };
  },
  computed: {},
  async mounted() {},
  watch: {
    files(newValue) {
      this.uploadedFiles = [...newValue];
    },
    ordernr(newValue) {
      this.orderNumber = newValue;
    }
  },

  methods: {
    ...mapActions({
      uploadFile: 'uploads/uploadFile'
    }),

    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {},
    drop(event) {
      event.preventDefault();
      this.$refs.fileUploader.files = event.dataTransfer.files;
      this.sendFile();
    },
    clickPDF(file) {
      let orderFiles = [];
      let fileName = file.substr(file.lastIndexOf('/') + 1, file.length);
      orderFiles.push(fileName);
      this.myOrder = {
        order_id: this.orderId,
        order_files: orderFiles,
        order_number: this.orderNumber
      };
      this.forceUpdateModalCounter = this.forceUpdateModalCounter + 1;
      this.isModalVisible = true;
    },

    closeModal() {
      this.showModal = false;
      this.isModalVisible = false;
    },

    async downloadPDF(file) {
      let fileName = file.substr(file.lastIndexOf('/') + 1, file.length);
      const payload = {
        file_name: fileName
      };
      await axiosAPI
        .post('/api/downloads/pdf', payload, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/pdf'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.errorMessage = 'PDF filen kunde inte hittas på servern.';
        });
    },

    validate(file) {
      const allowedTypes = ['application/pdf'];
      const MAX_SIZE = 10000000; //10MB

      if (file.size > MAX_SIZE) {
        // return `Too large. Max size is ${MAX_SIZE / 1000}Kb`;
        return `Files är för stor. Max storlek är ${MAX_SIZE / 1000000}MB`;
      }
      if (!allowedTypes.includes(file.type)) {
        return 'Bara PDFer är tillåtna';
      }
      return '';
    },
    async sendFile() {
      this.message = '';
      this.error = false;
      const newFiles = this.$refs.fileUploader.files;
      const maxCounter = this.uploadedFiles.length + newFiles.length;
      if (maxCounter > 4) {
        this.message = 'Max antal PDFer uppnått';
      } else {
        let formData = new FormData();
        formData.append('id', this.id);

        for (let i = 0; i < newFiles.length; i++) {
          const file = newFiles[i];
          if (this.validate(file) === '') {
            formData.append('files', file);
          } else {
            this.message = this.validate(file);
            this.error = true;
            this.uploading = false;
            return;
          }
        }

        try {
          this.uploading = true;
          this.progress = 0;
          const response = await axiosAPI.post('/api/uploads/files', formData, {
            onUploadProgress: (e) => (this.progress = Math.round((e.loaded * 100) / e.total))
          });
          let baseUrl = process.env.VUE_APP_FILE_BASE_URL;
          const myFiles = response.data.files;
          for (let i = 0; i < myFiles.length; i++) {
            const myFile = myFiles[i];
            let filePath = baseUrl.concat('/static/').concat(myFile);
            this.uploadedFiles.push(filePath);
          }
          this.uploading = false;
        } catch (error) {
          this.message = error.response.data.error;
          this.error = true;
          this.uploading = false;
        }
      }
    },
    async deleteFile(file) {
      let fileName = file.substr(file.lastIndexOf('/') + 1, file.length);
      try {
        let payload = {
          order_id: this.id,
          file_name: fileName
        };
        await axiosAPI.delete('/api/uploads/file', {
          data: payload
        });
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          if (this.uploadedFiles[i] === file) {
            this.uploadedFiles.splice(i, 1);
          }
        }
      } catch (error) {
        this.message = error.response.data.error;
        this.error = true;
        //  console.log(error);
        // TODO : FIX ERROR LOG
      }
    }
  }
};
</script>

<style scoped>
.message {
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  padding: 20px 0px;
  color: red;
}
.dropzone {
  min-height: 200px;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  outline: 2px dashed #868e96;
  /*outline-offset: -10px;*/
  background: #e9ecef;
  color: #495057;
  display: flex;
}
.dropzone .input-uploader {
  opacity: 0;
  width: 97%;
  height: 180px;
  position: absolute;
  cursor: pointer;
  background: green;
  z-index: 1;
}
.dropzone:hover {
  background: #ced4da;
}
.dropzone .call-to-action {
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  padding: 70px 0px;
}
.dropzone .progress-bar {
  text-align: center;
  width: 100%;
  padding: 70px 0px;
}

.file-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.single-file {
  position: relative;
  width: 40%;
  margin-bottom: 20px;
}
.file-image {
  display: block;
  width: 100%;
}

.file-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}
.file-overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.file-overlay:hover {
  opacity: 1;
}
.file-overlay:hover > * {
  transform: translateY(0);
}

.file-area .file-text {
  margin-bottom: 1em;
}

.file-button a {
  width: 50px;
  height: 50px;
  display: inline-block;

  margin: 0 5px;
  text-decoration: none;
  cursor: pointer;
}
.file-button a:hover {
  border: 1px solid #fff;
  border-radius: 5px;
}

.remove-file {
  display: inline;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-file:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-file:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}

.svg-icon-upload {
  display: block;
  margin: auto;
  width: 100px;
  padding: 70px 0px;
  /* 3db64a */
  filter: invert(57%) sepia(88%) saturate(381%) hue-rotate(76deg) brightness(88%) contrast(87%);
  z-index: 0;
}

.svg-icon-button {
  width: 3em;
  height: 3em;
  cursor: pointer;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(162deg) brightness(105%) contrast(104%);
}
</style>
